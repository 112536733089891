/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { slide as Menu } from "react-burger-menu"

import "../css/site.css"
import { Link } from "gatsby"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    {
      scelogo: file(relativePath: { eq: "scepower-logo-white.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 300) {
            src
          }
        }
      }
      scelogo_mobile: file(relativePath: { eq: "scepower-logo-white.png" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 250) {
            src
          }
        }
      }
    }
  `)

  return (
    <>
      <Menu width={"100%"}>
        <ul className="list-reset uppercase tracking-nav">
          <li className="p-2">
            <Link to="/" className="text-white">
              Home
            </Link>
          </li>
          <li className="p-2">
            <Link to="/about" className="text-white">
              About
            </Link>
          </li>
          <li className="p-2">
            <Link to="/team" className="text-white">
              Team
            </Link>
          </li>
          <li className="p-2">
            <Link to="/services" className="text-white">
              Services
            </Link>
          </li>
          <li className="p-2">
            <Link to="/projects" className="text-white">
              Projects
            </Link>
          </li>
          <li className="p-2">
            <Link to="/careers" className="text-white">
              Careers
            </Link>
          </li>          
          <li className="p-2">
            <Link to="/contact" className="text-white">
              Contact
            </Link>
          </li>
        </ul>
      </Menu>
      <div className="h-screen flex flex-col">
        <header className="flex border-b border-white container mx-auto items-center py-8">
          <div className="w-full md:w-1/3">
            <Link to="/">
              <img
                src={data.scelogo_mobile.childImageSharp.fluid.src}
                alt="Southeastern Consulting Engineers Logo"
                className="mx-auto md:mx-0"
              />
            </Link>
          </div>
          <div className="w-2/3 hidden md:block">
            <nav className="flex justify-end text-sm">
              <ul className="list-reset flex uppercase tracking-nav items-center">
                <li className="p-4">
                  <Link to="/" className="text-white">
                    Home
                  </Link>
                </li>
                <li className="p-4">
                  <Link to="/about" className="text-white">
                    About
                  </Link>
                </li>
                <li className="p-4">
                  <Link to="/team" className="text-white">
                    Team
                  </Link>
                </li>
                <li className="p-4">
                  <Link to="/services" className="text-white">
                    Services
                  </Link>
                </li>
                <li className="p-4">
                  <Link to="/projects" className="text-white">
                    Projects
                  </Link>
                </li>
                <li className="p-4">
                  <Link to="/careers" className="text-white">
                    Careers
                  </Link>
                </li>                
                <li className="p-4">
                  <Link
                    to="/contact"
                    className="text-white bg-red-500 hover:bg-red-700 flex"
                  >
                    <span className="p-2">Contact</span>
                    <span className="bg-red-700 p-2 pr-1 text-center">
                      <i className="fas fa-chevron-right"></i>
                    </span>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <main className="flex-grow">{children}</main>
        <footer className="bg-black py-16">
          <div className="container mx-auto flex">
            <div className="w-full md:w-1/3 text-center md:text-left">
              <img
                src={data.scelogo.childImageSharp.fluid.src}
                alt="Southeastern Consulting Engineers Logo"
                className="mx-auto md:mx-0"
              />
              <span className="font-bold block pt-12">Office</span>
              <address className="not-italic leading-loose">
                600 Minuet Ln <br />
                Charlotte, NC 28217 <br />
              </address>
              <span>704.523.6045</span>
              <span className="font-bold block mt-4">PO Box</span>
              <address className="not-italic pb-6 leading-loose">
                PO Box 240436 <br />
                Charlotte, NC 28224
              </address>
            </div>
            <div className="w-2/3 justify-end hidden md:flex">
              <div className="w-1/5">
                <ul className="list-reset uppercase tracking-nav">
                  <li className="p-2">
                    <Link to="/" className="text-white">
                      Home
                    </Link>
                  </li>
                  <li className="p-2">
                    <Link to="/about" className="text-white">
                      About
                    </Link>
                  </li>
                  <li className="p-2">
                    <Link to="/team" className="text-white">
                      Team
                    </Link>
                  </li>
                  <li className="p-2">
                    <Link to="/services" className="text-white">
                      Services
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="w-1/5">
                <ul className="list-reset uppercase tracking-nav">
                  <li className="p-2">
                    <Link to="/projects" className="text-white">
                      Projects
                    </Link>
                  </li>
                  <li className="p-2">
                    <Link to="/careers" className="text-white">
                      Careers
                    </Link>
                  </li>
                  <li className="p-2">
                    <Link to="/contact" className="text-white">
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="text-center text-sm pt-12 pb-4">
            <a
              href="http://www.systemseven.co"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-red-500"
            >
              website design and development by systemseven
            </a>
          </div>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
